/* eslint-disable */
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import 'moment/locale/hu';

import {
  getApplication,
  getApplications,
  ApplicationModel,
} from '../../redux/actions/applications.action';
import { getUserProfile, getPermissions } from '../../redux/actions/user.action';
import { setLanguage } from '../../redux/actions/language.action';

// routes config
import routes from '../../routes';
import { isAuthenticated } from '../../redux/services/auth.service';

import { UserModel } from '../../redux/reducers/user.reducer';
// import DefaultFooter from './DefaultFooter';

/* eslint-enable */

interface Props extends RouteComponentProps {
  getApplication: Function;
  getApplications: Function;
  application: ApplicationModel;
  applications: ApplicationModel[];
  language: string;
  getUserProfile: Function;
  user: UserModel;
  getPermissions: Function;
  permissions: any;
  setLanguage: Function;
}

const DefaultLayout: React.FC<Props | any> = props => {
  useEffect(() => {
    const lang = localStorage.getItem('i18nextLng') || 'en';

    if (isAuthenticated() && localStorage.unauthenticatedRoute) {
      window.location.hash = localStorage.unauthenticatedRoute;
      localStorage.removeItem('unauthenticatedRoute');
    }

    props.setLanguage(lang);
    /* eslint-disable-next-line */
  }, []);

  return (
    <div className="app">
      <main className="main">
        <Switch>
          {isAuthenticated() ? (
            routes.map(route => {
              return (
                route.component && (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    render={(props: any) => <route.component {...props} />}
                  />
                )
              );
            })
          ) : (
            <Redirect to="/login" />
          )}
          <Redirect to="/404" />
        </Switch>
      </main>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  applications: state.applications.data,
  user: state.user.data,
  permissions: state.permissions.data,
  language: state.language.data,
});

const mapDispatchToProps = {
  getApplication,
  getApplications,
  getUserProfile,
  getPermissions,
  setLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DefaultLayout));
