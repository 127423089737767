import { post } from '../../shared/services/http.service';
import { getToken } from './token.service';

export interface AuthCredentials {
  email: string;
  password: string;
}

export const login = async (credentials: AuthCredentials) => {
  const applicationId = +(localStorage.unauthenticatedRoute.match(/application\/(.*)\/gallery/i)?.[1]);

  return post('/login', {
    data: {
      applicationId,
      authProvider: 'HROPS_EMPLOYEE',
      key: credentials.email,
      secret: credentials.password,
    },
  });
};

export function isAuthenticated() {
  return getToken();
}
