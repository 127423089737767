import { get } from '../../shared/services/http.service';
import { settings } from '../../shared/settings';
import moment from 'moment';

const { DEFAULT_TRENDS_DAY_LIMIT } = settings;

export const getApplications = async (lastLoginAt: Date = new Date()) => {
  return get('/applications/detailed', {
    params: {
      from: lastLoginAt,
    },
  });
};

export const getApplication = async (
  applicationId: number,
  lastLoginAt: string = moment().subtract(7, 'd').utc().startOf('day').toISOString(),
  limit = DEFAULT_TRENDS_DAY_LIMIT,
) => {
  return get(`/applications/${applicationId}/detailed`, {
    params: {
      from: lastLoginAt,
      limit,
    },
  });
};
