import { StateModel } from '../../shared/models/default-state.model';
import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGOUT,
} from '../actions/auth.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const authReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case AUTH_LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    case AUTH_LOGOUT:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: null,
        data: {
          token: null,
          userData: null,
        },
      };

    default:
      return state;
  }
};

export default authReducer;
