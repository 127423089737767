import { Dispatch } from 'redux';
import { clearStore } from './store.action';
import { login as loginService, AuthCredentials } from '../services/auth.service';
import { removeToken } from '../services/token.service';

export const AUTH_LOGIN_REQUEST = 'auth/AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'auth/AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'auth/AUTH_LOGIN_ERROR';
export const AUTH_LOGOUT = 'auth/LOGOUT';

export const login = (credentials: AuthCredentials) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AUTH_LOGIN_REQUEST,
    });

    try {
      const token = await loginService(credentials);

      dispatch({
        type: AUTH_LOGIN_SUCCESS,
        payload: token,
      });
    } catch (error) {
      dispatch({
        type: AUTH_LOGIN_ERROR,
        error,
      });
    }
  };
};

export const logout = (): any => {
  return (dispatch: Dispatch) => {
    removeToken();
    dispatch(clearStore());

    dispatch({
      type: AUTH_LOGOUT,
    });
  };
};
