import { StateModel } from '../../shared/models/default-state.model';

import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  GET_TENANT_USERS_REQUEST,
  GET_TENANT_USERS_SUCCESS,
  GET_TENANT_USERS_ERROR,
  GET_TENANT_USER_REQUEST,
  GET_TENANT_USER_SUCCESS,
  GET_TENANT_USER_ERROR,
  CREATE_TENANT_USER_REQUEST,
  CREATE_TENANT_USER_SUCCESS,
  CREATE_TENANT_USER_ERROR,
  UPDATE_TENANT_USER_REQUEST,
  UPDATE_TENANT_USER_SUCCESS,
  UPDATE_TENANT_USER_ERROR,
  DELETE_TENANT_USER_REQUEST,
  DELETE_TENANT_USER_SUCCESS,
  DELETE_TENANT_USER_ERROR,
  GET_USER_GROUPS_REQUEST,
  GET_USER_GROUPS_SUCCESS,
  GET_USER_GROUPS_ERROR,
} from '../actions/user.action';

export interface UserModel {
  avatar: string;
  firstName: string;
  id: number;
  lastLoginAt: string;
  lastName: string;
  tenantId: number;
  tenantName: string;
  userGroupId: number;
  userGroupName: string;
}

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

export const userReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
      };

    case GET_USER_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const tenantUserReducer = (
  state: StateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case GET_TENANT_USER_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_TENANT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
      };

    case GET_TENANT_USER_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const tenantUserCreateReducer = (
  state: StateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case CREATE_TENANT_USER_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case CREATE_TENANT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
      };

    case CREATE_TENANT_USER_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const tenantUserUpdateReducer = (
  state: StateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case UPDATE_TENANT_USER_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case UPDATE_TENANT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
      };

    case UPDATE_TENANT_USER_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const tenantUserDeleteReducer = (
  state: StateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case DELETE_TENANT_USER_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case DELETE_TENANT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
      };

    case DELETE_TENANT_USER_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const tenantUsersReducer = (
  state: StateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case GET_TENANT_USERS_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_TENANT_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
      };

    case GET_TENANT_USERS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const tenantUserGroupsReducer = (
  state: StateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case GET_USER_GROUPS_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_USER_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
      };

    case GET_USER_GROUPS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
