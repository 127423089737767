import { get, post } from '../../shared/services/http.service';

const getGallery = async (applicationId: string, simpleListSlug: string) => {
  return get(
    `/applications/${applicationId}/simplelist/simple-lists/${simpleListSlug}/simple-elements/slug?sort=priority&direction=asc`,
  );
};

export const getImage = async (imageUrl: string) => {
  return getSignedUrlForDownloadService(imageUrl);
};

export const getSignedUrlForDownloadService = async (filenameString: string) => {
  const splitted = filenameString.split('.');
  const data = await post(`/signatures`, {
    data: {
      method: 'GET',
      filename: splitted[4].split('/')[1],
      extension: splitted[5].split('?')[0],
      toDownload: true,
    },
  });
  return data.signedUrl;
};

export default getGallery;
