import React, { useEffect, FC, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import FileSaver from 'file-saver';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';

import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { GalleryModel, GalleryServerModel } from 'src/shared/models';
import getGallery, {
  getImage,
  getSignedUrlForDownloadService,
} from 'src/redux/services/gallery.service';

import './Gallery.scss';
import DownloadIcon from '../../assets/file_download.svg';

interface Props {
  getApplication: Function;
  application: ApplicationModel;
}

const Gallery: FC<Props> = () => {
  const { appId, gallerySlug, photoIndex } = useParams<{
    appId: string;
    gallerySlug: string;
    photoIndex: string;
  }>();
  const [gallery, setGallery] = useState<Array<GalleryModel>>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    refreshGallery();

    /* eslint-disable-next-line */
  }, []);

  const refreshGallery = async () => {
    const gallery: Array<GalleryServerModel> = await getGallery(appId, gallerySlug);
    setGallery(
      gallery.map(galleryItem => ({
        original: galleryItem.fileUrl,
        thumbnail: galleryItem.imageUrl,
      })),
    );
  };

  const renderItem = (item: ReactImageGalleryItem) => {
    return <div className="galleryItem" style={{ backgroundImage: `url(${item.original})` }} />;
  };

  const downloadImage = async () => {
    const image = gallery[currentIndex];
    const file = await getSignedUrlForDownloadService(image.original);
    FileSaver.saveAs(file);
  };

  const renderCustomControls = () => {
    return (
      <div className="image-gallery-icon image-gallery-download-button" onClick={downloadImage}>
        <img src={DownloadIcon} alt="Download" />
      </div>
    );
  };

  const updateCurrentIndex = (currentIndex: number) => {
    setCurrentIndex(currentIndex);
  };

  return (
    <div className="animated fadeIn">
      <ImageGallery
        items={gallery}
        startIndex={+photoIndex}
        renderItem={renderItem}
        renderCustomControls={renderCustomControls}
        onSlide={updateCurrentIndex}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
  };
};

const mapDispatchToProps = {
  getApplication,
};

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
