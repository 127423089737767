import axios from 'axios';
import qs from 'query-string';
import jwt_decode from "jwt-decode";

import { HTTPMethods } from '../models/http-methods.model';

import { logout } from '../../redux/actions/auth.action';
import { setToken, getToken } from '../../redux/services/token.service';
import addToast from '../utils/notification.util';

import store from '../../redux';
import { settings } from '../settings';

export interface HTTPRequestModel {
  payload?: any;
  data?: any;
  params?: any;
  headers?: any;
  directRequest?: boolean;
}

interface userObject {
  tenantId: string;
  userId: string;
}

async function request(
  method: HTTPMethods = HTTPMethods.GET,
  path: string,
  config: HTTPRequestModel = {},
) {
  const { headers, data } = config;
  let { params } = config;

  const token = getToken();

  const {
    REACT_APP_AUTH_API_BASE_URL,
    REACT_APP_AUTH_API_BASE_PATH,
    REACT_APP_DATA_API_BASE_URL,
    REACT_APP_DATA_API_BASE_PATH,
  } = settings;

  const baseURL = /^\/(login|user|user-profile|permissions|change-password)/.test(path)
    ? `${REACT_APP_AUTH_API_BASE_URL}/${REACT_APP_AUTH_API_BASE_PATH}`
    : `${REACT_APP_DATA_API_BASE_URL}/${REACT_APP_DATA_API_BASE_PATH}`;

  // IE11 'cached-data' probléma megoldására.
  // Így a GET kérések eredményeit nem a cache-ből kapja.
  if (method === HTTPMethods.GET) {
    params = { ...params, time: Date.now() };
  }

  // Extract userObject from JWT token
  const decodedToken: userObject = jwt_decode(token!!);
  const { tenantId, userId } = decodedToken;

  let requestOptions = {
    baseURL,
    url: path,
    method,
    params,
    data,
    paramsSerializer: (params: any) => {
      return qs.stringify(params);
    },
    headers: {
      Authorization: `Bearer ${token}`,
      'X-User-Object': JSON.stringify({ tenantId, userId }),
      ...headers,
    },
  };

  if (/^http.+/.test(path)) {
    requestOptions = {
      ...requestOptions,
      headers: {
        ...headers,
      },
      baseURL: '',
    };
  }

  return axios
    .request(requestOptions)
    .then((response: any) => {
      if (response.data.token) {
        setToken(response.data.token);
      }

      if (response && response.data && response.data.message) {
        addToast('success', response.data.message);
      }

      return response.data;
    })
    .catch((err: any) => {
      const { dispatch } = store;
      dispatch(logout());
      throw err;
    });
}

export const get = (path: string, config?: HTTPRequestModel) => {
  return request(HTTPMethods.GET, path, config);
};

export const post = (path: string, config?: HTTPRequestModel) => {
  return request(HTTPMethods.POST, path, config);
};

export const put = (path: string, config?: HTTPRequestModel) => {
  return request(HTTPMethods.PUT, path, config);
};

export const del = (path: string, config?: HTTPRequestModel) => {
  return request(HTTPMethods.DELETE, path, config);
};

export const patch = (path: string, config?: HTTPRequestModel) => {
  return request(HTTPMethods.PATCH, path, config);
};
