import { logout } from '../../redux/actions/auth.action';

export const errorHandler = (store: any) => (next: any) => (action: any) => {
  return Promise.resolve(next(action)).catch(err => {
    const { response } = err;
    const { dispatch } = store;

    if (!response) {
      // possible 401
      dispatch(logout());
    }

    throw err;
  });
};

export default errorHandler;
