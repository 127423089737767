import React, { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';

import { login, logout } from '../../redux/actions/auth.action';

import './Login.scss';

import logo from '../../assets/img/brand/chatboss-1.svg';

interface Props {
  login: Function;
  logout: Function;
  token: string;
}

interface State {
  email: string;
  password: string;
}

class Login extends Component<Props | any, State | any> {
  state: State = {
    email: '',
    password: '',
  };

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    this.setState({ [name]: value });
  };

  handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const { email, password } = this.state;

    if (email && password) {
      this.props.login({ email, password });
    }
  };

  render() {
    const { email, password } = this.state;

    return (
      <div className="app login-container">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col lg="5" md="8">
              <Col className="logo-container">
                <img className="img-fluid" alt="Chatboss Logo" src={logo} />
              </Col>
              <CardGroup>
                <Card className="login-card">
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      {/* <h4 className="text-muted mb-4">Chatboss Dashboard</h4> */}
                      {/* <p className="text-muted">Sign In to your account</p> */}
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="login-input-group-text">
                            <i className="fas fa-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          name="email"
                          className="login-input email"
                          value={email}
                          placeholder="Email"
                          autoComplete="email"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="login-input-group-text">
                            <i className="fas fa-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Password"
                          name="password"
                          className="login-input password"
                          value={password}
                          autoComplete="current-password"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <Row>
                        <Col>
                          <Button className="login-btn" block color="primary">
                            Log In
                          </Button>
                        </Col>
                        {/* <Col xs="6" className="text-right">
                          <Button color="link" className="px-0">Forgot password?</Button>
                        </Col> */}
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                {/* <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: '44%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2>Sign up</h2>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua.</p>
                      <Link to="/register">
                        <Button color="primary" className="mt-3" active tabIndex={-1}>Register Now!</Button>
                      </Link>
                    </div>
                  </CardBody>
                </Card> */}
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ login, logout }, dispatch);
};

export default connect(
  null,
  mapDispatchToProps,
)(Login);
