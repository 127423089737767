import { toast, TypeOptions, ToastPosition } from 'react-toastify';

const config = {
  position: toast.POSITION.TOP_RIGHT as ToastPosition,
  autoClose: 5000,
};

const addToast = (type: TypeOptions, message: string) => {
  return toast(message, {
    ...config,
    type,
  });
};

export default addToast;
