import { StateModel } from '../../shared/models/default-state.model';

import {
  GET_USER_PERMISSIONS_REQUEST,
  GET_USER_PERMISSIONS_SUCCESS,
  GET_USER_PERMISSIONS_ERROR,
} from '../actions/user.action';

export interface UserModel {
  avatar: string;
  firstName: string;
  id: number;
  lastLoginAt: string;
  lastName: string;
  tenantId: number;
  tenantName: string;
  userGroupId: number;
  userGroupName: string;
}

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

export const permissionsReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_USER_PERMISSIONS_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_USER_PERMISSIONS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default permissionsReducer;
