import React from 'react';

// Common
import NotFound from '../views/NotFound';
// Summary

// Gallery
import Gallery from 'src/views/Gallery/Gallery';
import Welcome from 'src/views/Welcome/Welcome';


const routes = [
  {
    path: '/welcome',
    exact: true,
    component: () => (
        <Welcome />
    ),
  },
  {
    path: '/application/:appId/gallery/:gallerySlug/:photoIndex',
    exact: true,
    component: () => (
        <Gallery />
    ),
  },
  { path: '/404', exact: true, component: NotFound },
];

export default routes;
