import React, { Fragment, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { logout } from './redux/actions/auth.action';
import { isAuthenticated } from './redux/services/auth.service';
import './App.scss';

import Login from './containers/Login/Login';
import DefaultLayout from './containers/DefaultLayout/DefaultLayout';
import { setToken } from './redux/services/token.service';

interface Props {
  savedToken: string;
  logout: Function;
}

const App: React.FC<Props> = ({ logout }) => {
  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const token = useQuery().get('token');
  if (token) {
    setToken(token);
    window.location.href = window.location.href.split('?')[0];
  }

  if (!isAuthenticated() && window.location.hash.indexOf('gallery') !== -1) {
    localStorage.unauthenticatedRoute = window.location.hash;
  }

  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
    /* eslint-disable-next-line */
  }, []);

  return (
    <Fragment>
      <ToastContainer />

      <Switch>
        <Route exact path="/" component={() => <Redirect to="/login" />} />
        <Route
          exact
          path="/login"
          render={() => (isAuthenticated() ? <Redirect to="/welcome" /> : <Login />)}
        />
        <Route path="/" render={props => <DefaultLayout {...props} />} />
      </Switch>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  savedToken: state.token.data,
});

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
