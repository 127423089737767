import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './translations/en.json';
import hu from './translations/hu.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    // debug: process.env.NODE_ENV === 'development',
    debug: false,
    interpolation: {
      escapeValue: false,
      defaultVariables: {
        conversationWithUser: 'Unknown User',
      },
      format: (value, format, lng) => {
        if (format === 'lowercase') return value.toLowerCase();
        return value;
      },
    },
    lng: localStorage.getItem('i18nextLng') || 'en',
    react: {
      bindI18n: 'languageChanged',
      // bindStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: false,
    },
    resources: {
      en: {
        translation: en,
      },
      hu: {
        translation: hu,
      },
    },
  });

export default i18n;
