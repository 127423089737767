import {
  GET_APPLICATION_REQUEST,
  GET_APPLICATION_SUCCESS,
  GET_APPLICATION_ERROR,
  CLEAR_APPLICATION,
} from '../actions/applications.action';
import { StateModel } from '../../shared/models/default-state.model';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const applicationReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_APPLICATION_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_APPLICATION_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    case CLEAR_APPLICATION:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default applicationReducer;
