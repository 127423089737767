import { get, patch, post, del } from '../../shared/services/http.service';
import { UserModel } from '../../shared/models/user.model';

export const getUserProfile = async () => {
  return get('/user-profile');
};

export const getPermissions = async () => {
  return get('/permissions');
};

export const getTenantUsers = async () => {
  return get('/user');
};

export const getTenantUser = async (id: number) => {
  return get(`/user/${id}`);
};

export const createTenantUser = async (user: UserModel) => {
  return post('/user', { data: user });
};

export const upateTenantUser = async (id: number, user: UserModel) => {
  return patch(`/user/${id}`, { data: user });
};

export const deleteTenantUser = async (id: number) => {
  return del(`/user/${id}`);
};

export const getUserGroups = async () => {
  return get('/user-profile/user-groups/deep/flat');
};
