import { Dispatch } from 'redux';
import {
  getUserProfile as getUserProfileService,
  getTenantUser as getTenantUserService,
  getTenantUsers as getTenantUsersService,
  createTenantUser as createTenantUserService,
  upateTenantUser as updateTenantUserService,
  deleteTenantUser as deleteTenantUserService,
  getUserGroups as getUserGroupsService,
  getPermissions as getPermissionsService,
} from '../services/user.service';
import { UserModel } from 'src/shared/models/user.model';

export const GET_USER_PROFILE_REQUEST = 'user/GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'user/GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_ERROR = 'user/GET_USER_PROFILE_ERROR';

export const GET_TENANT_USER_REQUEST = 'user/GET_TENANT_USER_REQUEST';
export const GET_TENANT_USER_SUCCESS = 'user/GET_TENANT_USER_SUCCESS';
export const GET_TENANT_USER_ERROR = 'user/GET_TENANT_USER_ERROR';

export const GET_TENANT_USERS_REQUEST = 'user/GET_TENANT_USERS_REQUEST';
export const GET_TENANT_USERS_SUCCESS = 'user/GET_TENANT_USERS_SUCCESS';
export const GET_TENANT_USERS_ERROR = 'user/GET_TENANT_USERS_ERROR';

export const CREATE_TENANT_USER_REQUEST = 'user/CREATE_TENANT_USER_REQUEST';
export const CREATE_TENANT_USER_SUCCESS = 'user/CREATE_TENANT_USER_SUCCESS';
export const CREATE_TENANT_USER_ERROR = 'user/CREATE_TENANT_USER_ERROR';

export const UPDATE_TENANT_USER_REQUEST = 'user/UPDATE_TENANT_USER_REQUEST';
export const UPDATE_TENANT_USER_SUCCESS = 'user/UPDATE_TENANT_USER_SUCCESS';
export const UPDATE_TENANT_USER_ERROR = 'user/UPDATE_TENANT_USER_ERROR';

export const DELETE_TENANT_USER_REQUEST = 'user/DELETE_TENANT_USER_REQUEST';
export const DELETE_TENANT_USER_SUCCESS = 'user/DELETE_TENANT_USER_SUCCESS';
export const DELETE_TENANT_USER_ERROR = 'user/DELETE_TENANT_USER_ERROR';

export const GET_USER_PERMISSIONS_REQUEST = 'user/GET_USER_PERMISSIONS_REQUEST';
export const GET_USER_PERMISSIONS_SUCCESS = 'user/GET_USER_PERMISSIONS_SUCCESS';
export const GET_USER_PERMISSIONS_ERROR = 'user/GET_USER_PERMISSIONS_ERROR';

export const GET_USER_GROUPS_REQUEST = 'user/GET_USER_GROUPS_REQUEST';
export const GET_USER_GROUPS_SUCCESS = 'user/GET_USER_GROUPS_SUCCESS';
export const GET_USER_GROUPS_ERROR = 'user/GET_USER_GROUPS_ERROR';

export const getUserProfile = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_USER_PROFILE_REQUEST,
    });

    try {
      dispatch({
        type: GET_USER_PROFILE_SUCCESS,
        payload: await getUserProfileService(),
      });
    } catch (error) {
      dispatch({
        type: GET_USER_PROFILE_ERROR,
        error,
      });
    }
  };
};

export const getTenantUser = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TENANT_USER_REQUEST,
    });

    try {
      dispatch({
        type: GET_TENANT_USER_SUCCESS,
        payload: await getTenantUserService(id),
      });
    } catch (error) {
      dispatch({
        type: GET_TENANT_USER_ERROR,
        error,
      });
    }
  };
};

export const getTenantUsers = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TENANT_USERS_REQUEST,
    });

    try {
      dispatch({
        type: GET_TENANT_USERS_SUCCESS,
        payload: await getTenantUsersService(),
      });
    } catch (error) {
      dispatch({
        type: GET_TENANT_USERS_ERROR,
        error,
      });
    }
  };
};

export const createTenantUser = (user: UserModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_TENANT_USER_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_TENANT_USER_SUCCESS,
        payload: await createTenantUserService(user),
      });
    } catch (error) {
      dispatch({
        type: CREATE_TENANT_USER_ERROR,
        error,
      });
    }
  };
};

export const updateTenantUser = (id: number, user: UserModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_TENANT_USER_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_TENANT_USER_SUCCESS,
        payload: await updateTenantUserService(id, user),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_TENANT_USER_ERROR,
        error,
      });
    }
  };
};

export const deleteTenantUser = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_TENANT_USER_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_TENANT_USER_SUCCESS,
        payload: await deleteTenantUserService(id),
      });
    } catch (error) {
      dispatch({
        type: DELETE_TENANT_USER_ERROR,
        error,
      });
    }
  };
};

export const getPermissions = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_USER_PERMISSIONS_REQUEST,
    });

    try {
      dispatch({
        type: GET_USER_PERMISSIONS_SUCCESS,
        payload: await getPermissionsService(),
      });
    } catch (error) {
      dispatch({
        type: GET_USER_PERMISSIONS_ERROR,
        error,
      });
    }
  };
};

export const getUserGroups = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_USER_GROUPS_REQUEST,
    });

    try {
      dispatch({
        type: GET_USER_GROUPS_SUCCESS,
        payload: await getUserGroupsService(),
      });
    } catch (error) {
      dispatch({
        type: GET_USER_GROUPS_ERROR,
        error,
      });
    }
  };
};
