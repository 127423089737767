import { Dispatch } from 'redux';

export const SET_LANGUAGE = 'language/SET_LANGUAGE';

export const setLanguage = (language: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_LANGUAGE,
      payload: language,
    });
  };
};
