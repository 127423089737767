import { SET_LANGUAGE } from '../actions/language.action';

const INITIAL_STATE = {
  data: 'en',
};

export const languageReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        data: action.payload,
      };

    default:
      return state;
  }
};

export default languageReducer;
