import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApplicationModel } from '../../redux/actions/applications.action';

interface Props extends RouteComponentProps {
  applications: ApplicationModel[];
}

const Welcome: React.FC<Props> = () => {

  return (
    <div className="animated fadeIn">
      <h2>Chatboss Gallery</h2>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  applications: state.applications.data,
});

export default connect(mapStateToProps)(withRouter(Welcome));
