import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose as reduxCompose,
  Store,
} from 'redux';
import thunk from 'redux-thunk';

import { CLEAR_STORE } from './actions/store.action';

import { languageReducer } from './reducers/language.reducer';
import { applicationReducer } from './reducers/application.reducer';
import { applicationsReducer } from './reducers/applications.reducer';
import {
  userReducer
} from './reducers/user.reducer';
import { authReducer } from './reducers/auth.reducer';
import { errorHandler } from '../shared/middlewares/errorHandler';
// import { stateToLocalStorage } from '../shared/middlewares/stateToLocalStorage';
import { permissionsReducer } from './reducers/permissions.reducer';

const appReducer = combineReducers({
  // Language
  language: languageReducer,
  // Auth
  token: authReducer,
  permissions: permissionsReducer,
  // Users
  user: userReducer,
  // Applications
  application: applicationReducer,
  applications: applicationsReducer,
});

const compose =
  typeof window !== 'undefined'
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || reduxCompose
    : reduxCompose;

const rootReducer = (state: any, action: any) => {
  if (action.type === CLEAR_STORE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const store: Store = createStore(rootReducer, compose(applyMiddleware(errorHandler, thunk)));

// stateToLocalStorage(store, ['platforms']);

export default store;
